"use client";
import Image from "next/image";
import { Helmet } from "react-helmet";
// -------------- 组件 -------------------- //
import HeaderFooterWrapper from "@/components/headerFooterWrapper";
// -------------- 图标 -------------------- //
import aircraft from "@/assets/icons/aircraft.png";
// -------------- constants -------------------- //
import { PAGE_TYPE } from "@/common-components-src/js/main";
// -------------- styles -------------------- //
import styles from "@/styles/notFound.module.scss";

// app/not-found.js
export default function NotFound() {
  return (
    <HeaderFooterWrapper pageType={PAGE_TYPE.NORMAL}>
      <Helmet>
        <title>Page Not Found | Joybuy</title>
        <meta
          name="description"
          content="Oops! The page you're looking for cannot be found. Explore top products with fast shipping and delivery guarantee at Joybuy.com. Return to the homepage for great deals!"
        />
      </Helmet>
      <div className={styles.container}>
        <div>
          <Image width={120} height={120} src={aircraft.src} alt="404 not found" />
          <div className={styles.title}>404 Page Not Found</div>
        </div>
      </div>
    </HeaderFooterWrapper>
  );
}
